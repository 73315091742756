import PageBanner from "../../../../src/components/PageBanner";
import Button from "../../../../src/components/Button";
import { newslettersUrl, clubInscriptionLink, discordLink } from "../../../../src/settings";
import * as React from 'react';
export default {
  PageBanner,
  Button,
  newslettersUrl,
  clubInscriptionLink,
  discordLink,
  React
};